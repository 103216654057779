import styled from "styled-components";

const Wrapper = styled.nav`
    #profile-dropdown {
        display: flex;
        align-items: center;
        padding: 0;
    }
`;

export default Wrapper;
