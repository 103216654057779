import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import GoogleSignInButton, { getUser, signOut } from "../../component/GoogleSigninButton";
import { NavDropdown } from "react-bootstrap";

const AdminPage = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [user, setUser] = useState(getUser());
    const adminId = user?._id;

    const apiURL = "https://backend-auth-eosin.vercel.app/";

    useEffect(() => {
        if (!user || user.role !== "admin") {
            navigate("/");
            return;
        }

        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${apiURL}api/admin/users`, {
                    params: { userId: adminId },
                });
                setUsers(response.data);
            } catch (error) {
                console.error("Error fetching users:", error.response?.data || error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [user, adminId, navigate]);

    const handleRoleChange = async (userId, newRole) => {
        try {
            await axios.patch(`${apiURL}api/admin/users/${userId}`, {
                role: newRole,
                userId: adminId,
            });
            setUsers(users.map((user) => (user._id === userId ? { ...user, role: newRole } : user)));
        } catch (error) {
            console.error("Error updating role:", error.response?.data || error.message);
        }
    };

    const handleDeleteUser = async (userId) => {
        try {
            await axios.delete(`${apiURL}api/admin/users/${userId}`, {
                data: { userId: adminId },
            });
            setUsers(users.filter((user) => user._id !== userId));
        } catch (error) {
            console.error("Error deleting user:", error.response?.data || error.message);
        }
    };

    if (loading) return <p className="text-muted">Loading...</p>;

    return (
        <div className="container mt-4">
            <div className="d-flex mb-4 align-items-center justify-content-between">
                <h2 className="mb-0">Admin Panel</h2>
                {user ? (
                    <NavDropdown title={<img src={user.picture} className="rounded-circle object-fit-cover ms-1" alt="Profile" height="40" width="40" />} id="profile-dropdown" align="end">
                        <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                        <NavDropdown.Item href="/settings">Settings</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#" onClick={signOut}>
                            Logout
                        </NavDropdown.Item>
                    </NavDropdown>
                ) : (
                    <GoogleSignInButton onSignIn={setUser} />
                )}
            </div>

            {users.length > 0 ? (
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user._id}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>
                                    <select className="custom-select" value={user.role} onChange={(e) => handleRoleChange(user._id, e.target.value)}>
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                        <option value="host">Host</option>
                                    </select>
                                </td>
                                <td>
                                    <button className="btn btn-danger btn-sm w-100" onClick={() => handleDeleteUser(user._id)}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-muted">No users found.</p>
            )}
        </div>
    );
};

export default AdminPage;
